import React, { useContext, useState } from "react";
import "./FeaturedWork.css";
import Navbar from "../../components/navbar/Navbar";
import { ThemeContext } from "../../contexts/ThemeContext";
import openInNewDark from "../../images/open-in-new-dark.svg";
import openInNewLight from "../../images/open-in-new-light.svg";
import credleyImg from "../../images/projects/credley-img.png";
import altepayImg from "../../images/projects/altepay-img.png";
import simptominiImg from "../../images/projects/simptomini-img.png";
import docilImg from "../../images/projects/docil-img.png";
import finakipImg from "../../images/projects/finakip-img.png";
import marineportalImg from "../../images/projects/marineportal-img.png";
import propcoImg from "../../images/projects/propco-img.png";

function FeaturedWork() {
  // Functions, states and variables
  const { isDarkTheme }: any = useContext(ThemeContext);
  // Featured Projects
  const featuredProjects = [
    {
      clientName: "Credley",
      projectImg: credleyImg,
      projectSummary: "Redefining the way people receive their salaries",
      liveSiteUrl: "https://credley.africa",
    },
    {
      clientName: "Altepay",
      projectImg: altepayImg,
      projectSummary: "Savings and purchases made easier",
      liveSiteUrl: "https://www.getaltepay.com/",
    },
  ];

  // Other Works
  const otherWorks = [
    {
      clientName: "Marine Insurance App(HEIRS)",
      projectImg: marineportalImg,
      projectSummary:
        "Application for generating and managing marine insurances",
      liveSiteUrl: "https://marineportal.heirsinsurance.com/",
    },
    {
      clientName: "Simptomini",
      projectImg: simptominiImg,
      projectSummary: "Website for a digital healthcare provider",
      liveSiteUrl: "https://www.simptomini.com",
    },
    {
      clientName: "Propco",
      projectImg: propcoImg,
      projectSummary: "Easily invest in rental properties across Africa",
      liveSiteUrl: "https://www.mypropco.com/",
    },
    {
      clientName: "Finakip",
      projectImg: finakipImg,
      projectSummary: "Finance management website",
      liveSiteUrl: "https://www.finakip.com",
    },
    {
      clientName: "Docil",
      projectImg: docilImg,
      projectSummary: "Website and management system for DOCIL",
      liveSiteUrl: "https://www.docil.africa",
    },
  ];
  // States
  const [isNavbarDropdownOpened, setIsNavbarDropdownOpened] = useState(false);
  return (
    <div
      className={`featured-work-container ${
        isDarkTheme ? "dark-theme-bg" : "light-theme-bg"
      } `}
    >
      <Navbar
        isNavbarDropdownOpened={isNavbarDropdownOpened}
        setIsNavbarDropdownOpened={setIsNavbarDropdownOpened}
      />
      <div
        className={`featured-work-container--inner ${
          isDarkTheme ? "dark-theme-text" : "light-theme-text"
        }`}
      >
        {/* Featured Works Section */}
        <section className="featured-works-section">
          <div
            className="row-title featured-works-title"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            Featured Works
          </div>

          {/* Works Featured work box container */}
          {featuredProjects?.map((project, index) => (
            <div key={index + 1} className="featured-work-box-container">
              {/* Home Featured work left wrapper */}
              <div className="featured-work-box---left-wrapper">
                {/* Top Wrapper */}
                <div
                  className="featured-work-box--left--top-wrapper"
                  data-aos="fade-right"
                  data-aos-delay={200 * (index + 1)}
                >
                  <div className="client-name-wrapper">
                    {project.clientName}
                  </div>
                  <div className="project-summary-wrapper featured-works--project-summary-wrapper">
                    {project.projectSummary}{" "}
                  </div>
                </div>
                {/* Bottom Wrapper */}
                <div className="featured-work-box--left--bottom-wrapper">
                  <div
                    className="live-site-link-wrapper"
                    data-aos="flip-up"
                    data-aos-delay={200 * (index + 1)}
                  >
                    <a
                      href={project.liveSiteUrl}
                      rel="noreferrer"
                      target={"_blank"}
                      className={
                        isDarkTheme ? "dark-theme-text" : "light-theme-text"
                      }
                    >
                      <img
                        src={isDarkTheme ? openInNewDark : openInNewLight}
                        alt=""
                      />
                      view live site
                    </a>
                  </div>
                </div>
              </div>

              {/* Home Featured work right wrapper */}
              <div className="featured-work-box---right-wrapper">
                <div
                  className="featured-work-project-img-wrapper"
                  data-aos="fade-left"
                  data-aos-delay={100 * (index + 1)}
                >
                  <img src={project.projectImg} alt="" />
                </div>
              </div>
            </div>
          ))}
        </section>

        {/* Other Works Section */}
        <section className="other-works-section">
          <div
            className="row-title featured-works-title"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Other Works
          </div>

          {/* Other works boxes wrapper */}
          <div className="other-works-boxes-wrapper">
            {otherWorks?.map((project, index) => (
              <div key={index + 1} className="other-works-box-container">
                <div
                  className="other-works-img-wrapper"
                  data-aos="fade-up"
                  data-aos-delay={150 * (index + 1)}
                >
                  <img src={project.projectImg} alt="" />
                </div>
                <div className="client-name-wrapper other-works-client-name-wrapper">
                  {project.clientName}
                </div>
                <div className="project-summary-wrapper">
                  {project.projectSummary}
                </div>
                <div className="live-site-link-wrapper other-works--live-site-link-wrapper">
                  <a
                    href={project.liveSiteUrl}
                    rel="noreferrer"
                    target={"_blank"}
                    className={
                      isDarkTheme ? "dark-theme-text" : "light-theme-text"
                    }
                  >
                    <img
                      src={isDarkTheme ? openInNewDark : openInNewLight}
                      alt=""
                    />
                    view live site
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default FeaturedWork;
