import { useState, useEffect } from "react";
import cancelImg from "../../images/cancel.svg";
import styles from "./ErrorHandler.module.css";
import { motion } from "framer-motion";
import { ErrorHandlerType } from "../../Types";

interface IProps {
  errorHandlerObj: ErrorHandlerType;
  className?: string;
}

const ErrorHandler = function ({ errorHandlerObj, className }: IProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
    if (errorHandlerObj.hasError) {
      setShow(true);
    }
  }, [errorHandlerObj]);

  return (
    <>
      {show ? (
        <motion.div
          variants={{
            hidden: {
              y: -200,
            },
            visible: {
              y: 100,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className={`${styles.error_handler_container} ${className || ""}`}
        >
          {/* {className} */}
          <div className={styles.error_handler_header}>
            <div className="error-handler-title">{errorHandlerObj.message}</div>

            <div className={styles.cancel_error_button_wrrapper}>
              <button onClick={() => setShow(false)}>
                <img src={cancelImg} alt="" />
              </button>
            </div>
          </div>
        </motion.div>
      ) : (
        ""
      )}
    </>
  );
};

export default ErrorHandler;
