import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import companyLogoDark from "../../images/full-techminte-logo-dark.svg";
import companyLogoLight from "../../images/full-techminte-logo-light.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import navburgerLight from "../../images/navburger-light.svg";
import navburgerDark from "../../images/navburger-dark.svg";
import navcloseLight from "../../images/navclose-light.svg";
import navcloseDark from "../../images/navclose-dark.svg";
import moonIcon from "../../images/moon-icon.svg";
import sunIcon from "../../images/sun-icon.svg";
import { ThemeContext } from "../../contexts/ThemeContext";

interface IProps {
  isNavbarDropdownOpened: boolean;
  setIsNavbarDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
}
const Navbar = function ({
  isNavbarDropdownOpened,
  setIsNavbarDropdownOpened,
}: IProps) {
  // States, Functions and Variables
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkTheme, toggleTheme }: any = useContext(ThemeContext);

  // States

  // Functions

  useEffect(() => {
    setIsNavbarDropdownOpened(false);
  }, [location]);

  useEffect(() => {
    // Scroll into the hash view container if hash exists on location change
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Also scroll to top of page on location change
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="navbar-section">
      {/* Nav Dropdown Container */}
      <section
        className={`nav-dropdown-container ${
          isDarkTheme ? "dark-theme-bg" : "light-theme-bg"
        } ${isNavbarDropdownOpened ? "" : "collapsed"}`}
      >
        <div className="nav-dropdown-container--inner">
          {/* Navdropdown Top Wrapper */}
          <div className={`nav-dropdown--top-wrapper`}>
            <div className="app-logo-wrapper">
              <Link to={"/"}>
                <img
                  src={isDarkTheme ? companyLogoDark : companyLogoLight}
                  alt=""
                />
              </Link>
            </div>
            <div className="navclose-button-wrapper">
              <button onClick={() => setIsNavbarDropdownOpened(false)}>
                <img src={isDarkTheme ? navcloseDark : navcloseLight} alt="" />
              </button>
            </div>
          </div>

          {/* Navdropdown Bottom Wrapper */}
          <div className={`nav-dropdown--bottom-wrapper`}>
            {/* Home Nav Link Wrapper */}
            <div
              className={`navbar-link-wrapper home-link-wrapper ${
                isDarkTheme ? "dark-theme" : "light-theme"
              }`}
            >
              <Link to={"/"}>Home</Link>
            </div>

            {/* Work Nav Link Wrapper */}
            <div
              className={`navbar-link-wrapper work-link-wrapper ${
                isDarkTheme ? "dark-theme" : "light-theme"
              }`}
            >
              <Link to={"/work"}>Work</Link>
            </div>

            {/* Work Nav Link Wrapper */}
            <div
              className={`navbar-link-wrapper contact-link-wrapper ${
                isDarkTheme ? "dark-theme" : "light-theme"
              }`}
            >
              <Link to={"/#contact"}>Contact</Link>
            </div>
          </div>
        </div>
      </section>

      {/* Navbar container */}
      <div
        className={`navbar-container ${
          isDarkTheme ? "dark-theme" : "light-theme"
        }`}
      >
        {/* Navbar container inner */}
        <div className="navbar-container--inner">
          {/* Navbar Left */}
          <div className="navbar--left-section-wrapper">
            <div className="app-logo-wrapper" data-aos="fade-down">
              <Link to={"/"}>
                <img
                  src={isDarkTheme ? companyLogoDark : companyLogoLight}
                  alt=""
                />
              </Link>
            </div>
          </div>

          {/* Navbar Right */}
          <div className="navbar--right-section-wrapper">
            {/* Dark Theme /Light Theme Toggle Button Wrapper */}
            <div
              className="theme-toggle-button-wrapper"
              data-aos="fade-down"
              data-aos-delay="100"
            >
              <button onClick={() => toggleTheme()}>
                <img src={isDarkTheme ? sunIcon : moonIcon} alt="" />
              </button>
            </div>

            <div
              className="navburger-button-wrapper"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              <button
                onClick={() =>
                  setIsNavbarDropdownOpened(!isNavbarDropdownOpened)
                }
              >
                <img
                  src={isDarkTheme ? navburgerDark : navburgerLight}
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
