import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./Home.css";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../components/button/Button";
import Navbar from "../../components/navbar/Navbar";
import { ThemeContext } from "../../contexts/ThemeContext";
import truckCraneDarkImg from "../../images/truck-crane-dark.svg";
import truckCraneLightImg from "../../images/truck-crane-light.svg";
import greystarDark from "../../images/greystar--company-logo.png";
import simptominiDark from "../../images/simptomini-dark--company-logo.png";
import simptominiLight from "../../images/simptomini-light--company-logo.png";
import credleyDark from "../../images/credley-dark--company-logo.png";
import credleyLight from "../../images/credley-light--company-logo.png";
import visaprofDark from "../../images/visaprof-dark--company-logo.png";
import visaprofLight from "../../images/visaprof-light--company-logo.png";
import heirsDark from "../../images/heirs-dark--company-logo.png";
import heirsLight from "../../images/heirs-light--company-logo.png";
import hardeDark from "../../images/harde-dark--company-logo.png";
import hardeLight from "../../images/harde-light--company-logo.png";
import finakipDark from "../../images/finakip-dark--company-logo.png";
import finakipLight from "../../images/finakip-light--company-logo.png";
import docilDark from "../../images/docil-dark--company-logo.png";
import docilLight from "../../images/docil-light--company-logo.png";
import understandImg from "../../images/understand.png";
import designImg from "../../images/design.png";
import developmentImg from "../../images/development.png";
import launchImg from "../../images/launch.png";
import wrigglyArrowDark from "../../images/wriggly-arrow-dark.svg";
import wrigglyArrowLight from "../../images/wriggly-arrow-light.svg";
import phoneDark from "../../images/call-dark.svg";
import phoneLight from "../../images/call-light.svg";
import mailDark from "../../images/mail-dark.svg";
import mailLight from "../../images/mail-light.svg";
import locationDark from "../../images/location-dark.svg";
import locationLight from "../../images/location-light.svg";
import Footer from "../../components/footer/Footer";
import ideaImg from "../../images/idea.png";
import openInNewDark from "../../images/open-in-new-dark.svg";
import openInNewLight from "../../images/open-in-new-light.svg";
import credleyImg from "../../images/projects/credley-img.png";
import altepayImg from "../../images/projects/altepay-img.png";
import axios from "axios";
import { api } from "../../api/config";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import ErrorHandler from "../../components/error-handler/ErrorHandler";
import SuccessHandler from "../../components/success-handler/SuccessHandler";

function Home() {
  // Functions, states and variables
  const { isDarkTheme }: any = useContext(ThemeContext);
  const navigate = useNavigate();
  const allClientCompanies = [
    { name: "Greystar", imgDark: greystarDark, imgLight: greystarDark },
    { name: "Simptomini", imgDark: simptominiDark, imgLight: simptominiLight },
    { name: "Credley", imgDark: credleyDark, imgLight: credleyLight },
    { name: "Visaprof", imgDark: visaprofDark, imgLight: visaprofLight },
    { name: "Heirs Insurance", imgDark: heirsDark, imgLight: heirsLight },
    { name: "Harde Business School", imgDark: hardeDark, imgLight: hardeLight },
    { name: "Docil", imgDark: docilDark, imgLight: docilLight },
    { name: "Finakip", imgDark: finakipDark, imgLight: finakipLight },
  ];

  const featuredProjects = [
    {
      clientName: "Credley",
      projectImg: credleyImg,
      projectSummary: "Redefining the way people receive their salaries",
      liveSiteUrl: "https://credley.africa",
    },
    {
      clientName: "Altepay",
      projectImg: altepayImg,
      projectSummary: "Savings and purchases made easier",
      liveSiteUrl: "https://www.getaltepay.com/",
    },
  ];
  //   States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNavbarDropdownOpened, setIsNavbarDropdownOpened] = useState(false);

  const {
    successHandlerObj,
    errorHandlerObj,
    setSuccessHandlerObj,
    setErrorHandlerObj,
  } = useDisplayMessage();

  // Functions
  const handleSubmitContactForm = function (e: MouseEvent<HTMLButtonElement>) {
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    if (name !== "" && email !== "" && message !== "" && phonenumber !=='') {
      e.preventDefault();
      const data = {
        name,
        message,
        email,
        phonenumber,
      };

      //  Call the API to submit the form data
      setIsLoading(true);
      const url = `${api}/api/v1/contact`;
      // setSuccessHandlerObj({
      //   isSuccess: true,
      //   message: "Thank you. Your message has reached Tech minte.",
      // });
      axios
        .post(url, data)
        .then(() => {
          setSuccessHandlerObj({
            isSuccess: true,
            message: "Thank you. Your message has reached Tech minte.",
          });
        })
        .catch((err) => {
          setErrorHandlerObj({
            hasError: true,
            message: "Something went wrong, please try again!",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // UseEffects
  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setName("");
      setEmail("");
      setPhonenumber("");
      setMessage("");

      // Clear success message
      setTimeout(() => {
        setSuccessHandlerObj({ isSuccess: false, message: "" });
      }, 7000);
    }
  }, [successHandlerObj]);

  return (
    <div
      className={`home-container ${
        isDarkTheme ? "dark-theme-bg" : "light-theme-bg"
      }`}
      style={{ position: "relative" }}
    >
      <Navbar
        isNavbarDropdownOpened={isNavbarDropdownOpened}
        setIsNavbarDropdownOpened={setIsNavbarDropdownOpened}
      />

      {/* Error handler component */}
      <ErrorHandler
        errorHandlerObj={errorHandlerObj}
        className={"app--actions-handler-wrapper"}
      />

      <SuccessHandler
        successHandlerObj={successHandlerObj}
        className={"app--actions-handler-wrapper"}
      />

      <div className="home-container--inner">
        {/* Home Row One Section */}
        <section
          className={`home--row-one-section ${
            isDarkTheme ? "dark-theme-text" : "light-theme-text"
          }`}
        >
          {/* Red blurred ball */}
          <div className="blurred-balls red-blurred-ball"></div>
          {/* Yellow blurred ball */}
          <div className="blurred-balls yellow-blurred-ball"></div>
          {/* Blue blurred ball */}
          <div className="blurred-balls blue-blurred-ball"></div>

          {/* Main Text Container */}
          <div className="home--row-one-main-text-container">
            {/* Left Wrapper */}
            <div className="home--row-one-main-text--left-wrapper">
              <div className="you-think-wrapper" data-aos="fade-right">
                You Think
              </div>
              <div
                className="we-build-wrapper"
                data-aos="fade-right"
                data-aos-delay="250"
              >
                We Build
              </div>
            </div>
            {/* Left Wrapper */}
            <div className="home--row-one-main-text--right-wrapper">
              <div
                className="it-wrapper"
                data-aos="fade-left"
                data-aos-delay="250"
              >
                IT
              </div>
            </div>
          </div>

          {/* Sub Text Container */}
          <div
            className="home--row-one-sub-text-container sub-text-wrapper"
            data-aos="fade-up"
            data-aos-delay="350"
          >
            We combine a solid <span className="yellow-span">design</span> and{" "}
            <span className="cyan-span">development</span> expertise to help you
            create fascinating digital experiences
          </div>

          {/* Start A Project Button */}
          <Button
            name={"Start a project"}
            className={"start-a-project-button-wrapper"}
            onClick={() => navigate("/#contact")}
            dataAos="fade-up"
            dataAosDelay="200"
          />
        </section>

        {/* Home Row Two Section */}
        <section
          className={`home--row-two-section ${
            isDarkTheme ? "dark-theme-text" : "light-theme-text"
          }`}
        >
          {/* Left Container */}
          <div className="home--row-two--left-container">
            {/* About Us Summary */}
            <div
              className="about-us-summary-wrapper sub-text-wrapper"
              data-aos="fade-up"
            >
              We are a{" "}
              <span className="pink-span">software development agency</span>{" "}
              based in Nigeria with more than 5 years of experience building
              modern and usable software solutions for our clients
            </div>

            {/* Works and Clients Numbers Summary */}
            <div className="works-and-clients-amount-summary-wrapper">
              {/* Number of clients */}
              <div className="amount-of-clients-wrapper" data-aos="flip-up">
                <div className="amount-of-clients-value">50+</div>
                <div className="amount-of-clients-title">
                  clients worked with
                </div>
              </div>
              {/* Number of Projects */}
              <div
                className="amount-of-projects-wrapper"
                data-aos="flip-up"
                data-aos-delay="150"
              >
                <div className="amount-of-projects-value">80+</div>
                <div className="amount-of-projects-title">
                  successful projects
                </div>
              </div>
            </div>

            <div className="client-company-logos-wrapper">
              {allClientCompanies?.map((company, i) => (
                <div
                  key={i + 1}
                  className="client-company-logo"
                  data-aos="flip-right"
                  data-aos-delay={150 * (i + 1)}
                >
                  <img
                    src={isDarkTheme ? company.imgDark : company.imgLight}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Right Container */}
          <div className="home--row-two--right-container">
            <div className="truck-crane-img-wrapper" data-aos="fade-up">
              <img
                src={isDarkTheme ? truckCraneDarkImg : truckCraneLightImg}
                alt=""
              />
            </div>
          </div>
        </section>

        {/* Home Row Three Section */}
        <section
          className={`home--row-three-section ${
            isDarkTheme ? "dark-theme-text" : "light-theme-text"
          }`}
        >
          <div
            className="how-we-work-title-wrapper sub-text-wrapper"
            data-aos="fade-up"
          >
            At Techminte, we make use of an agile method of development to build
            scalable solutions.
          </div>

          {/* Agile method visualization wrapper */}
          <div className="agile-method-visualization-wrapper non-slider-visualization-wrapper">
            {/* Understand */}
            <div className="methodology-image-wrapper">
              <img src={understandImg} alt="" />
              <span>Understand</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Design */}
            <div className="methodology-image-wrapper">
              <img src={designImg} alt="" />
              <span>Design</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Development */}
            <div className="methodology-image-wrapper">
              <img src={developmentImg} alt="" />
              <span>Development</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Launch */}
            <div className="methodology-image-wrapper">
              <img src={launchImg} alt="" />
              <span>Launch</span>
            </div>
          </div>

          {/* Infinite Slide Carousel of Agile Method */}
          {/* Agile method visualization wrapper */}
          <div className="agile-method-visualization-wrapper slide-track">
            {/* Understand */}
            <div className="methodology-image-wrapper slide">
              <img src={understandImg} alt="" />
              <span>Understand</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Design */}
            <div className="methodology-image-wrapper">
              <img src={designImg} alt="" />
              <span>Design</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Development */}
            <div className="methodology-image-wrapper">
              <img src={developmentImg} alt="" />
              <span>Development</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Launch */}
            <div className="methodology-image-wrapper">
              <img src={launchImg} alt="" />
              <span>Launch</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Understand */}
            <div className="methodology-image-wrapper slide">
              <img src={understandImg} alt="" />
              <span>Understand</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Design */}
            <div className="methodology-image-wrapper">
              <img src={designImg} alt="" />
              <span>Design</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Development */}
            <div className="methodology-image-wrapper">
              <img src={developmentImg} alt="" />
              <span>Development</span>
            </div>

            {/* Arrow */}
            <div className="wriggly-img-wrapper">
              <img
                src={isDarkTheme ? wrigglyArrowDark : wrigglyArrowLight}
                alt=""
              />
            </div>

            {/* Launch */}
            <div className="methodology-image-wrapper">
              <img src={launchImg} alt="" />
              <span>Launch</span>
            </div>
          </div>
        </section>

        {/* Home Row Four Section */}
        <section
          className={`home--row-four-section ${
            isDarkTheme ? "dark-theme-text" : "light-theme-text"
          }`}
        >
          <div className="row-title featured-works-title" data-aos="fade-up">
            Featured Work
          </div>

          {/* Home Featured work box container */}
          {featuredProjects?.map((project, index) => (
            <div key={index + 1} className="featured-work-box-container">
              {/* Home Featured work left wrapper */}
              <div className="featured-work-box---left-wrapper">
                {/* Top Wrapper */}
                <div
                  className="featured-work-box--left--top-wrapper"
                  data-aos="fade-right"
                  data-aos-delay={100 * (index + 1)}
                >
                  <div className="client-name-wrapper">
                    {project.clientName}
                  </div>
                  <div className="project-summary-wrapper featured-works--project-summary-wrapper">
                    {project.projectSummary}{" "}
                  </div>
                </div>
                {/* Bottom Wrapper */}
                <div className="featured-work-box--left--bottom-wrapper">
                  <div
                    className="live-site-link-wrapper"
                    data-aos="flip-up"
                    data-aos-delay={200 * (index + 1)}
                  >
                    <a
                      href={project.liveSiteUrl}
                      rel="noreferrer"
                      target={"_blank"}
                      className={
                        isDarkTheme ? "dark-theme-text" : "light-theme-text"
                      }
                    >
                      <img
                        src={isDarkTheme ? openInNewDark : openInNewLight}
                        alt=""
                      />
                      view live site
                    </a>
                  </div>
                </div>
              </div>

              {/* Home Featured work right wrapper */}
              <div className="featured-work-box---right-wrapper">
                <div
                  className="featured-work-project-img-wrapper"
                  data-aos="fade-left"
                  data-aos-delay={100 * (index + 1)}
                >
                  <img src={project.projectImg} alt="" />
                </div>
              </div>
            </div>
          ))}

          {/* View More Button */}
          <Button
            name={"View More"}
            className={"view-more-works-button-wrapper"}
            onClick={() => navigate("/work")}
            dataAos="fade-up"
            dataAosDelay="200"
          />
        </section>

        {/* Home Row Five Section */}
        <section
          className={`home--row-five-section ${
            isDarkTheme ? "dark-theme-text" : "light-theme-text"
          }`}
        >
          <div className="idea-img-wrapper">
            <img src={ideaImg} alt="" className="idea-img-one idea-img" />
            <img src={ideaImg} alt="" className="idea-img-two idea-img" />
            <img src={ideaImg} alt="" className="idea-img-three idea-img" />
            <img src={ideaImg} alt="" className="idea-img-four idea-img" />
          </div>

          {/* Row Header Wrapper */}
          <div className="home--row-five-section-header-wrapper">
            {/* Title wrapper */}
            <div className="home--row-five-section-title-wrapper">
              <span data-aos="fade-up" data-aos-delay="100">
                Let's
              </span>
              <span data-aos="fade-up" data-aos-delay="200">
                talk
              </span>
            </div>

            {/* Contact Info Container */}
            <div className="contact-info-container" id="contact">
              {/* Phone Number */}
              <div
                className="contact-info-wrapper phone-number-wrapper"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img src={isDarkTheme ? phoneDark : phoneLight} alt="" />
                <span>
                  <Link
                    to={"#"}
                    onClick={() =>
                      (window.location.href = "tel:+2347035807350")
                    }
                    className={
                      isDarkTheme ? "dark-theme-text" : "light-theme-text"
                    }
                  >
                    +234 703 580 7350
                  </Link>
                </span>
              </div>

              {/* Email */}
              <div
                className="contact-info-wrapper email-wrapper"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img src={isDarkTheme ? mailDark : mailLight} alt="" />
                <span>
                  <Link
                    to={"#"}
                    onClick={() =>
                      (window.location.href = "mailto:info@techminte.com")
                    }
                    className={
                      isDarkTheme ? "dark-theme-text" : "light-theme-text"
                    }
                  >
                    info@techminte.com
                  </Link>
                </span>
              </div>
              {/* Location */}
              <div
                className="contact-info-wrapper locations-wrapper"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <img src={isDarkTheme ? locationDark : locationLight} alt="" />
                <span>Ikeja, Lagos</span>
              </div>
            </div>
          </div>

          {/* Home row Five Body Wrapper  */}
          <div className="home--row-five-body-wrapper" id={"start-a-project"}>
            <div className="contact-form-container">
              {/* COntact form header wrapper */}
              <div
                className="contact-form-header-wrapper"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <span>Have an interesting idea you want to work on?</span>
                <span>Just fill in the form and we’ll get back to you</span>
              </div>
              <form>
                {/* Name Form group */}
                <div
                  className={`form-group name-form-group ${
                    isDarkTheme ? "dark-theme" : "light-theme"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <label htmlFor="name-input">Your Name</label>
                  <input
                    type="text"
                    id="name-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                {/* Email Form Group */}
                <div
                  className={`form-group name-form-group ${
                    isDarkTheme ? "dark-theme" : "light-theme"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <label htmlFor="email-input">Your Email</label>
                  <input
                    type="email"
                    id="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                {/* Email Form Group */}
                <div
                  className={`form-group name-form-group ${
                    isDarkTheme ? "dark-theme" : "light-theme"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <label htmlFor="phone-input">Your Phone Number</label>
                  <input
                    type="text"
                    id="phoe-input"
                    value={phonenumber}
                    onChange={(e) => setPhonenumber(e.target.value)}
                    required
                  />
                </div>

                {/* Message Form Group */}
                <div
                  className={`form-group message-form-group ${
                    isDarkTheme ? "dark-theme" : "light-theme"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <label htmlFor="name-input">Your Message</label>
                  <textarea
                    id="message-textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>

                {/* View More Button */}
                <Button
                  name={isLoading ? "Submitting..." : "Submit Form"}
                  className={"submit-form-button-wrapper"}
                  onClick={(e) => handleSubmitContactForm(e)}
                  dataAos="fade-up"
                  dataAosDelay="100"
                  disabled={isLoading}
                />
              </form>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
