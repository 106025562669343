import React, { createContext, useState } from "react";

interface IThemeContextProviderProps {
  children: React.ReactNode;
}

export interface IThemeContextType {
  isDarkTheme: boolean;
  setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<IThemeContextType | null>(null);
function ThemeContextProvider({ children }: IThemeContextProviderProps) {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  // Toggle Theme
  const toggleTheme = function () {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <ThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
