import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./Footer.css";

function Footer() {
  // Functions, States and variables
  const { isDarkTheme }: any = useContext(ThemeContext);

  const date = new Date();
  return (
    <div
      className={`footer-container ${
        isDarkTheme
          ? "dark-theme-bg dark-theme-text"
          : "light-theme-bg light-theme-text"
      }`}
    >
      <div className={`footer-container--inner`}>
        <div className="copyright-wrapper">{date.getFullYear()}, techminte</div>
      </div>
    </div>
  );
}

export default Footer;
