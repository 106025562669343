import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import cancelImg from "../../images/cancel.svg";
import styles from "./SuccessHandler.module.css";
import { SuccessHandlerType } from "../../Types";

interface IProps {
  successHandlerObj: SuccessHandlerType;
  className?: string;
}

const SuccessHandler = function ({ successHandlerObj, className }: IProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
    if (successHandlerObj.isSuccess) {
      setShow(true);
    }
  }, [successHandlerObj]);

  return (
    <>
      {show ? (
        <motion.div
          variants={{
            hidden: {
              y: -200,
            },
            visible: {
              y: 100,
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className={`${styles.success_handler_container} ${className || ""}`}
        >
          {/* {className} */}
          <div className={styles.success_handler_header}>
            <div className="success-handler-title">
              {successHandlerObj.message}
            </div>

            <div className={styles.cancel_success_button_wrrapper}>
              <button onClick={() => setShow(false)}>
                <img src={cancelImg} alt="" />
              </button>
            </div>
          </div>
        </motion.div>
      ) : (
        ""
      )}
    </>
  );
};

export default SuccessHandler;
