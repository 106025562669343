import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import ThemeContextProvider from "./contexts/ThemeContext";
import FeaturedWork from "./pages/featured-work/FeaturedWork";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
      duration: 1200,
    });
  }, []);
  return (
    <div className="app">
      <ThemeContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/work" element={<FeaturedWork />} />
          </Routes>
        </Router>
      </ThemeContextProvider>
    </div>
  );
}

export default App;
