import React, { useContext } from "react";
import "./Button.css";
import arrowRight from "../../images/arrow-right.svg";
import { ThemeContext } from "../../contexts/ThemeContext";

interface IProps {
  name: string;
  className: string;
  disabled?: boolean;
  onClick: (e: any) => void;
  dataAos?: string;
  dataAosDelay?: string;
}

function Button({
  name,
  className,
  disabled = false,
  onClick,
  dataAos,
  dataAosDelay,
}: IProps) {
  // Functions, States and variables
  const { isDarkTheme }: any = useContext(ThemeContext);
  return (
    <div
      className={`button-wrapper ${className} ${
        isDarkTheme ? "dark-theme" : "light-theme"
      }`}
      data-aos={dataAos}
      data-aos-delay={dataAosDelay}
    >
      <button disabled={disabled} onClick={onClick}>
        {name}
        <div className="arrow-icon-wrapper">
          <img src={arrowRight} alt="" />
        </div>
      </button>
    </div>
  );
}

export default Button;
